<template>
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="#FFA15F"
    xmlns="http://www.w3.org/2000/svg"
    :class="[customClass.toString()]"
  >
    <path
      d="M12.3577 0.116058C7.14941 0.116058 2.7015 3.35564 0.899414 7.92856C2.7015 12.5015 7.14941 15.7411 12.3577 15.7411C17.5661 15.7411 22.014 12.5015 23.8161 7.92856C22.014 3.35564 17.5661 0.116058 12.3577 0.116058ZM12.3577 13.1369C9.48275 13.1369 7.14941 10.8036 7.14941 7.92856C7.14941 5.05356 9.48275 2.72022 12.3577 2.72022C15.2327 2.72022 17.5661 5.05356 17.5661 7.92856C17.5661 10.8036 15.2327 13.1369 12.3577 13.1369ZM12.3577 4.80356C10.6286 4.80356 9.23275 6.19939 9.23275 7.92856C9.23275 9.65772 10.6286 11.0536 12.3577 11.0536C14.0869 11.0536 15.4827 9.65772 15.4827 7.92856C15.4827 6.19939 14.0869 4.80356 12.3577 4.80356Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEye",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
